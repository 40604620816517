<template>
  <div className="animated fadeIn">

      <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table">

        <template slot="h__id">
          <div class="text-center">
           <input type='checkbox' id='checkbox' @click='selectAll()'>
            </div>
        </template>

        <div slot="id" slot-scope="props">

            <input type="checkbox" class="form-control" v-model='props.row.isChecked'>
        </div>

          <!--<template slot="status" slot-scope="data">
            <b-badge :variant="getStatus(data.status)">{{data.status}}</b-badge>
          </template> -->
          <!--
          <template slot="actions" slot-scope="props">
            <b-button variant="secondary" :to="{ name: 'Product', params: { id: props.row.id } }">View</b-button>
          </template>
          -->

      </v-server-table>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {ServerTable} from 'vue-tables-2'
  import axios from '../../shared/axios/auth'
  Vue.use(ServerTable)


  let url = '/documents/';

  export default {
    name: 'DataTable',
    components: {
      ServerTable,
    },
    data: function () {
      return {
        columns: [
          /*'id',*/ "name", /*'actions'*/],
        data: [],
        checkedData: [],
        fields: [
         /* {
          name: '__checkbox',
          titleClass: 'center aligned',
          dataClass: 'center aligned'
        },*/
        ],
        options: {
          requestFunction: (data) => {
              return axios.get(url, {
                  params: data
              }).catch(error => {
                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('quotes error');
                console.log(error.response)
              });
          },
          responseAdapter: function(resp) {
            var data = this.getResponseData(resp);
            return { data: data.data.data, count: data.data.pagination.total }
          },
          headings: {
          },
          sortable: ['name'],
          filterable: ['name', ],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    methods: {
      getPolicies() {
        axios.get(url).then(
          response => (
            this.data = response.data
          )
        ).catch(error => {
              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });

        return this.data
      },
    },
    computed: {
      user_permissions() {
        return this.$store.getters.getUserPermissions
      },
      hasAccess() {

        let access = ['full_admin', 'customer_services'];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
    },
    mounted() {
      //this.getPolicies()
    }
  };
</script>

<style>

  #dataTable  table {
    background-color: #fff;
  }
  .form-inline label {
      justify-content: left;

  }


</style>
